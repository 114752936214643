import React, { useEffect, useState } from "react"
import * as navOverlayStyles from "./navOverlay.module.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import { BiMenu } from "react-icons/bi"

const NavOverlay = () => {
  const [showBurger, setShowBurger] = useState(true)

  const controlBurger = () => {
    if (window.scrollY > 100) {
      setShowBurger(false)
    } else {
      setShowBurger(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", controlBurger)
    return () => {
      window.removeEventListener("scroll", controlBurger)
    }
  }, [])

  const closeMenu = () => {
    document.getElementById("fullscreen-menu").style.top = "-100vh"
  }

  const openMenu = () => {
    document.getElementById("fullscreen-menu").style.top = "0"
  }

  return (
    <div className={navOverlayStyles.wrapper}>
      {showBurger && (
        <button tabIndex={0} className={navOverlayStyles.menuIconWrapper}>
          <BiMenu
            className={`${navOverlayStyles.menuIcon} `}
            onClick={openMenu}
          />
        </button>
      )}
      <nav id="fullscreen-menu" className={navOverlayStyles.nav}>
        <StaticImage
          className={navOverlayStyles.logo}
          src="../../images/LOGO.png"
          alt="Modern Eye Logo"
          placeholder="blurred"
          style={{
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        />

        <ul className={navOverlayStyles.ul}>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/"
            title="Home"
          >
            {" "}
            Home
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/news"
            title="News"
          >
            News
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/services"
            title="Services"
          >
            Services
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/frames"
            title="Frames"
          >
            Frames
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/lenses"
            title="Lenses"
          >
            Lenses
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/locations"
            title="Locations"
          >
            Locations
          </Link>
          <Link
            onClick={closeMenu}
            className={navOverlayStyles.link}
            to="/contact"
            title="Contact"
          >
            Contact
          </Link>
        </ul>
        <div className={navOverlayStyles.socialIcons}>
          <a
            className={navOverlayStyles.socialLink}
            href="https://www.instagram.com/moderneyephilly"
            target="__blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FaInstagramSquare className={navOverlayStyles.icon} />
          </a>

          <a
            className={navOverlayStyles.socialLink}
            href="https://www.facebook.com/moderneye.fb/"
            target="__blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FaFacebookSquare className={navOverlayStyles.icon} />
          </a>
          <a
            className={navOverlayStyles.socialLink}
            href="https://twitter.com/moderneye2"
            target="__blank"
            rel="noreferrer"
            title="Twitter"
          >
            <FaTwitterSquare className={navOverlayStyles.icon} />
          </a>
        </div>
        <button className={navOverlayStyles.closeIcon}>
          <IoMdClose
            className={navOverlayStyles.closeIcon}
            onClick={closeMenu}
          />
        </button>
      </nav>
    </div>
  )
}

export default NavOverlay
