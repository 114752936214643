import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as headerStyles from "./header.module.scss"
import universitycenter from "../../images/header/universitycenter.png"
import NavLinksResponsive from "./navlinksResponsive"
import NavOverlay from "./navOverlay"
import SashBanner from "../global/SashBanner"

const Header = () => {
  return (
    <>
      <div className={headerStyles.sashBannerDesktop}>
        <SashBanner />
      </div>
      <div className={headerStyles.wrapper} id="header">
        {/* header with animation */}
        <div className={headerStyles.grid}>
          <div className={headerStyles.animation}>
            <StaticImage
              src="../../images/header/homeslide1.jpg"
              alt=""
              style={{ width: `100%`, height: `100%`, opacity: "0" }}
              placeholder="blurred"
            />
          </div>
          {/* logo */}
          <Link
            className={headerStyles.logo}
            to="/"
            title="Home"
            id="top-nav-logo"
          >
            <div>
              <StaticImage
                alt="Modern Eye Logo"
                src="../../images/LOGO.png"
                placeholder="tracedSVG"
                layout="fullWidth"
              />
            </div>
          </Link>

          <Link
            className={headerStyles.universityLink}
            to="/locations"
            title="Locations"
          >
            <img
              className={headerStyles.university}
              src={universitycenter}
              alt="University City and Center City"
            />
          </Link>
        </div>

        <NavOverlay />
        <NavLinksResponsive />
        <div className={headerStyles.sashBannerMobile}>
          <SashBanner />
        </div>
      </div>
    </>
  )
}

export default Header
