import React, { useState, useEffect } from "react"

export const GlobalStateContext = React.createContext()

const GlobalContextProvider = ({ children }) => {
  const [showAll, setShowAll] = useState(true)
  const [showUniversity, setShowUniversity] = useState(false)
  const [showCenter, setShowCenter] = useState(false)
  const [showCovidModal, setShowCovidModal] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowCovidModal(true)
  //   }, 1000)
  // }, [])

  return (
    <GlobalStateContext.Provider
      value={{
        showAll,
        showUniversity,
        showCovidModal,
        showCenter,
        setShowCovidModal,
        openCovidModal: () => {
          setShowCovidModal(true)
        },
        closeCovidModal: () => {
          setShowCovidModal(false)
        },
        showAllClick: () => {
          setShowAll(true)
          setShowUniversity(false)
          setShowCenter(false)
        },

        showUniversityClick: () => {
          setShowAll(false)
          setShowUniversity(true)
          setShowCenter(false)
        },

        showCenterClick: () => {
          setShowAll(false)
          setShowUniversity(false)
          setShowCenter(true)
        },
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export default ({ element }) => (
  <GlobalContextProvider>{element}</GlobalContextProvider>
)
