import React from "react"
import * as footerStyles from "./footer.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import fb from "../images/icons/fb.png"
import insta from "../images/icons/instagram.png"
import twitter from "../images/icons/twitter.png"
import scrollTo from "gatsby-plugin-smoothscroll"
import { doc } from "prettier"

const Footer = () => {
  return (
    <footer id="footer" className={footerStyles.footerWrapper}>
      <h2 className="sr-only">Footer</h2>
      <div className={footerStyles.footerGrid}>
        <div className={footerStyles.uCity}>
          <h3 className={footerStyles.locationName}>
            MODERN EYE UNIVERSITY CITY
          </h3>
          <div className={footerStyles.locationList}>
            <address>
              3419 Walnut St. <br />
              The Shops at Penn <br />
              Philadelphia, PA 19104
            </address>
            <a
              className={footerStyles.phoneLinks}
              href="tel:215-386-5953"
              title="Call Modern Eye University City"
            >
              t.215-386-5953
            </a>
            <br />
            <a
              className={footerStyles.phoneLinks}
              href="fax:215-386-3508"
              title="Fax Modern Eye University City"
            >
              f.215-386-3508
            </a>
          </div>
        </div>
        <div className={footerStyles.centerCity}>
          <h3 className={footerStyles.locationName}>MODERN EYE CENTER CITY</h3>
          <div className={footerStyles.locationList}>
            <address>
              145 South 13th St. <br />
              (NE corner of 13th & Walnut) <br />
              Philadelphia, PA 19107
            </address>
            <a
              className={footerStyles.phoneLinks}
              href="tel:215-922-3300"
              title="Call Modern Eye Center City"
            >
              t.215-922-3300
            </a>{" "}
            <br />
            <a
              className={footerStyles.phoneLinks}
              href="fax:215-922-0775"
              title="Fax to Modern Eye Center City"
            >
              f.215-922-0775
            </a>
          </div>
        </div>
        {/* totop */}
        <div className={footerStyles.socialIcons} role="group">
          <a
            href="https://www.facebook.com/moderneye.fb/"
            target="__blank"
            rel="noreferrer"
            aria-label="Facebook Link"
            title="Join us on Facebook"
          >
            <img className={footerStyles.icon} src={fb} alt="facebook logo" />
          </a>
          <a
            href="https://www.instagram.com/moderneyephilly"
            target="__blank"
            rel="noreferrer"
            aria-label="Instagram Link"
            title="Follow us on Instagram"
          >
            <img
              className={footerStyles.icon}
              src={insta}
              alt="instagram logo"
            />
          </a>
          <a
            href="https://twitter.com/moderneye2"
            target="__blank"
            rel="noreferrer"
            aria-label="Twitter Link"
            title="Follow us on Twitter"
          >
            <img
              className={footerStyles.icon}
              src={twitter}
              alt="twitter logo"
            />
          </a>
        </div>
        <div className={footerStyles.hours}>
          <h3 className={footerStyles.hoursOfOperation}>Hours of operation:</h3>
          <ul className={footerStyles.hoursList}>
            <li>Monday through Friday 10am to 6pm</li>
            <li>Saturday 10am to 5pm</li>
            <li>(Tuesdays 9am to 6pm University City only)</li>
          </ul>
        </div>
        <div className={footerStyles.copyright}>
          Copyright {new Date().getFullYear()} Modern Eye. All rights reserved
        </div>
        <button
          id="to-top"
          title="To Top"
          onClick={() => {
            scrollTo("#header")
            //focus on #header
            document.getElementById("top-nav-logo").focus()
          }}
          className={footerStyles.toTopWrapper}
        >
          <StaticImage
            className={footerStyles.toTopHand}
            src="../images/icons/to-top-hand.png"
            alt=""
          />
          <span className={footerStyles.toTopText}>to top</span>
        </button>
      </div>
    </footer>
  )
}

export default Footer
