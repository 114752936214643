// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-frames-js": () => import("./../../../src/pages/frames.js" /* webpackChunkName: "component---src-pages-frames-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lenses-js": () => import("./../../../src/pages/lenses.js" /* webpackChunkName: "component---src-pages-lenses-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-single-frame-js": () => import("./../../../src/templates/single-frame.js" /* webpackChunkName: "component---src-templates-single-frame-js" */)
}

