import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as sashBannerStyles from "./SashBanner.module.scss"

const SashBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/sash-banners/" }
          frontmatter: { visible: { eq: true } }
        }
        sort: { fields: [frontmatter___endDate], order: [DESC] }
      ) {
        edges {
          node {
            frontmatter {
              title
              startDate
              endDate
              href
              content
              rank
            }
          }
        }
      }
    }
  `)

  // Check if banners exist
  if (!data || !data.allMarkdownRemark.edges.length) {
    return null
  }

  const banners = data.allMarkdownRemark.edges
    .map(edge => edge.node.frontmatter)
    .filter(banner =>
      banner.endDate ? new Date(banner.endDate) >= new Date() : true
    )

  if (!banners.length) {
    return null
  }

  // Select the banner with the highest rank and the most recent end date
  const selectedBanner = banners.reduce((prev, current) => {
    const prevRank = prev.rank || 0
    const currentRank = current.rank || 0

    if (prevRank === currentRank) {
      return new Date(prev.endDate) > new Date(current.endDate) ? prev : current
    }
    return prevRank > currentRank ? prev : current
  })

  return (
    <div className={sashBannerStyles.wrapper}>
      {selectedBanner.href ? (
        <a href={selectedBanner.href}>
          <div dangerouslySetInnerHTML={{ __html: selectedBanner.content }} />
        </a>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: selectedBanner.content }} />
      )}
    </div>
  )
}

export default SashBanner
