// extracted by mini-css-extract-plugin
export var closeIcon = "navOverlay-module--closeIcon--4Ycs0";
export var closeMenu = "navOverlay-module--closeMenu--oSEvb";
export var icon = "navOverlay-module--icon--shvy+";
export var link = "navOverlay-module--link--UKCF0";
export var logo = "navOverlay-module--logo--7aZBn";
export var menuIcon = "navOverlay-module--menuIcon--6fHIS";
export var menuIconWrapper = "navOverlay-module--menuIconWrapper--pzRfP";
export var nav = "navOverlay-module--nav--bmIVM";
export var socialIcons = "navOverlay-module--socialIcons--+7msg";
export var socialLink = "navOverlay-module--socialLink--rvwD0";
export var ul = "navOverlay-module--ul--KBjzn";
export var wrapper = "navOverlay-module--wrapper--rrfH7";