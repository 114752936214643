// extracted by mini-css-extract-plugin
export var animation = "header-module--animation--x2UsQ";
export var fader = "header-module--fader--WS8zU";
export var grid = "header-module--grid--Emhkn";
export var logo = "header-module--logo--WAoX3";
export var logoFocus = "header-module--logoFocus--t-Qr0";
export var logoWrapper = "header-module--logoWrapper--IeGRq";
export var sashBannerDesktop = "header-module--sashBannerDesktop--iwIuI";
export var sashBannerMobile = "header-module--sashBannerMobile--ImN0c";
export var university = "header-module--university--23ZM9";
export var universityLink = "header-module--universityLink--GZLS2";
export var wrapper = "header-module--wrapper--CU6iV";