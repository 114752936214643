import React from "react"
import * as navlinksStyles from "./navlinksResponsive.module.scss"
import { Link } from "gatsby"

const NavLinksResponsive = () => {
  return (
    <div className={navlinksStyles.wrapper}>
      <nav id="top-nav">
        <ul className={`${navlinksStyles.linksList} `} id="js-nav">
          <li className={navlinksStyles.links}>
            <Link className={navlinksStyles.links} to="/news" title="News">
              News
            </Link>
          </li>
          <li className={navlinksStyles.links}>
            <Link
              className={navlinksStyles.links}
              to="/services"
              title="Services"
            >
              Services
            </Link>
          </li>
          <li className={navlinksStyles.links}>
            <Link className={navlinksStyles.links} to="/frames" title="Frames">
              Frames
            </Link>
          </li>
          <li className={navlinksStyles.links}>
            <Link className={navlinksStyles.links} to="/lenses" title="Lenses">
              Lenses
            </Link>
          </li>
          <li className={navlinksStyles.links}>
            <Link
              className={navlinksStyles.links}
              to="/locations"
              title="Locations"
            >
              Locations
            </Link>
          </li>
          <li className={navlinksStyles.links}>
            <Link
              className={navlinksStyles.links}
              to="/contact"
              title="Contact"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default NavLinksResponsive
